footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 15px 15px;
  box-sizing: border-box;
}

footer .copyright {
  color: rgb(163, 172, 185);
  font-size: 0.8rem;
}
