header {
  width: 100vw;
  height: 50px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
}

header .logo {
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 1rem;
  user-select: none;
}

header .logo::first-letter {
  font-family: "Italianno";
  font-size: 2rem;
}

header .logo span:nth-child(2) {
  font-size: 0.675rem;
  letter-spacing: 2px;
  display: none;
}

header a {
  color: inherit;
  text-decoration: none;
}
