.order-success-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.success-notification h1 {
  font-weight: 200;
}

.success-notification p {
  font-weight: 100;
}
