.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: hsl(240, 0%, 9%);
  color: white;
}

.app > *:nth-child(2) {
  flex-grow: 1;
}
